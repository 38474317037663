* {
  image-rendering: pixelated;
}

.donation-progress {
  opacity: 0;
  width: 1200px;
  height: 80px;
  position: absolute;
  left: 360px;
}

.donation-progress .donation-totals {
  color: #fff;
  filter: drop-shadow(0 0 2px #1115);
  font-family: teko;
  font-size: 24px;
  position: absolute;
  top: -30px;
  right: 0;
}

.donation-progress .donation-flair {
  color: #fff;
  font-family: teko;
  font-size: 24px;
  position: absolute;
  top: -30px;
  left: 0;
}

.donation-progress .donation-flair .flair-objective {
  color: #ed3636;
  font-family: teko-light;
  display: inline;
}

.donation-progress .donation-bar-size {
  width: 1200px;
  height: 35px;
}

.donation-progress .donation-bar-container {
  position: absolute;
  bottom: -40px;
  overflow: hidden;
}

.donation-progress .donation-border {
  z-index: 10;
  background: url("donation-goal-border.9ddd1481.png");
  position: absolute;
}

.donation-progress .donation-background {
  z-index: 0;
  background: url("donation-goal-bg.c70d0d2d.png");
  position: absolute;
}

.donation-progress .donation-bar-progressbar {
  z-index: 5;
  background: url("donation-goal-bar.666b8d98.png");
  width: 1192px;
  height: 23px;
  position: absolute;
  top: 6px;
  left: -1192px;
}

.donation-progress .donation-bar-progressbar .percent {
  color: #201303;
  font-family: teko;
  font-size: 20px;
  position: absolute;
  top: -1px;
  right: 8px;
}

#donation-feedback {
  width: 200px;
  height: 250px;
  position: absolute;
  top: 1093px;
  left: 850px;
}

#donation-feedback .donation {
  color: #f3f3f3;
  text-shadow: 2px 0 #1115, 1.8478px .7654px #1115, 1.4142px 1.4142px #1115, .7654px 1.8478px #1115, 0 2px #1115, -.7654px 1.8478px #1115, -1.4142px 1.4142px #1115, -1.8478px .7654px #1115, -2px 0 #1115, -1.8478px -.7654px #1115, -1.4142px -1.4142px #1115, -.7654px -1.8478px #1115, 0 -2px #1115, .7654px -1.8478px #1115, 1.4142px -1.4142px #1115, 1.8478px -.7654px #1115;
  font-family: teko;
  font-size: 24px;
  position: absolute;
}

#donation-feedback .donation-l, #donation-feedback .donation-xl {
  color: #e2881d;
}

#donation-feedback .donation-xxl {
  color: gold;
}
/*# sourceMappingURL=index.40f55c30.css.map */
